<template>
  <!-- Navigation -->
  <nav
    class="navbar navbar-expand-md navbar-dark navbar-custom fixed-top"
    :class="active"
  >
    <!-- Text Logo - Use this if you don't have a graphic logo -->
    <!-- <a class="navbar-brand logo-text page-scroll" href="index.html">Leno</a> -->

    <!-- Image Logo -->
    <router-link
      class="navbar-brand logo-image"
      to="/"
      style="text-decoration: none"
      >Sticker Makers</router-link
    >

    <!-- Mobile Menu Toggle Button -->
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarsExampleDefault"
      aria-controls="navbarsExampleDefault"
      aria-expanded="false"
      aria-label="Toggle navigation"
      @click="spreadNav"
    >
      <!-- <span class="navbar-toggler-awesome fas fa-bars"></span> -->
      <span v-if="!spread" class="spread">
        <span class="spread-item"></span>
        <span class="spread-item"></span>
        <span class="spread-item"></span>
      </span>
      <span v-else class="close">
        <span class="close-item"></span>
        <span class="close-item"></span>
      </span>
      <!-- <span class="navbar-toggler-awesome fas fa-times"></span> -->
    </button>
    <!-- end of mobile menu toggle button -->

    <div
      class="collapse navbar-collapse"
      id="navbarsExampleDefault"
      :style="{ display }"
    >
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link class="nav-link page-scroll" to="/">HOME </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link page-scroll" to="privacy"
            >PRIVACY</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link page-scroll" to="terms"
            >TERMS</router-link
          >
        </li>

        <li class="nav-item">
          <router-link class="nav-link page-scroll" to="/contact"
            >CONTACT</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
  <!-- end of navbar -->
  <!-- end of navigation -->
</template>

<script>
export default {
  data() {
    return {
      active: "",
      spread: false,
      display: "",
    };
  },
  methods: {
    spreadNav() {
      this.spread = !this.spread;
      this.display = this.spread ? "block" : "none";
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.active = scrollTop > 20 ? "top-nav-collapse" : "";
    });
  },
};
</script>

<style lang="scss">
.navbar-toggler {
  border: none;
  outline: none;
  background-color: transparent;
  .spread {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 30px;
    .spread-item {
      width: 26px;
      height: 3px;
      border-radius: 1px;
      background: $white;
    }
  }
  .close {
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    .close-item {
      width: 30px;
      height: 3px;
      border-radius: 1px;
      background: $white;
      transform-origin: center center;
      position: absolute;
      opacity: 1;
      left: 50%;
      top: 50%;
      &:first-child {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:last-child {
        transform: translate(-50%, -50%) rotate(135deg);
      }
    }
  }
}
</style>
