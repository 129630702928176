<template>
  <div class="home">
    <Banner></Banner>
    <!-- <Terms></Terms> -->
  </div>
</template>

<script>
import Banner from "@/components/Banner/Banner";
// import Terms from "@/components/Terms/Terms";
export default {
  name: "Home",
  components: { Banner },
};
</script>
