<template>
  <section class="contact-us">
    <!-- Header -->
    <header id="header" class="ex-header">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>CONTACT US</h1>
          </div>
        </div>
        <!-- end of row -->
      </div>
      <!-- end of container -->
    </header>
    <!-- end of ex-header -->
    <!-- end of header -->
    <!-- Contact -->
    <div id="contact" class="form">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>Get in Touch with Us</h2>
            <ul class="list-unstyled li-space-lg">
              <li class="address">
                Don’t hesitate to contact us if any question or suggestions.
              </li>
              <!-- <li><i class="fas fa-map-marker-alt"></i>XXX</li>
              <li>
                <i class="fas fa-phone"></i
                ><a class="blue" href="tel:003024630820">XXX</a>
              </li>
              <li>
                <i class="fas fa-envelope"></i
                ><a class="blue" href="mailto:XXX">XXX</a>
              </li> -->
            </ul>
          </div>
          <!-- end of col -->
        </div>
        <!-- end of row -->
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <!-- Contact Form -->
            <form
              action="javascript:;"
              id="contactForm"
              data-toggle="validator"
              data-focus="false"
            >
              <DropSelect
                v-model="selectValue"
                :dropDataList="dropList"
              ></DropSelect>
              <InputFiled
                id="name"
                v-model="name"
                inputType="text"
                label="Name"
              ></InputFiled>
              <InputFiled
                id="email"
                v-model="email"
                inputType="email"
                label="Email"
              ></InputFiled>

              <InputFiled
                id="message"
                v-model="message"
                inputType="textarea"
                label="Your message"
              ></InputFiled>
              <div class="form-group checkbox">
                <input
                  type="checkbox"
                  id="cterms"
                  value="Agreed-to-Terms"
                  required
                  v-model="checked"
                />I have read and agree to
                <router-link to="/privacy">Privacy Policy</router-link> and
                <router-link to="terms">Terms of Use</router-link>
                <div class="help-block with-errors"></div>
              </div>
              <div class="form-group" @click.stop="sendMessage">
                <button type="submit" class="form-control-submit-button">
                  SUBMIT MESSAGE
                </button>
              </div>
              <div class="form-message">
                <div id="cmsgSubmit" class="h3 text-center hidden"></div>
              </div>
            </form>
            <!-- end of contact form -->
          </div>
          <!-- end of col -->
        </div>
        <!-- end of row -->
      </div>
      <!-- end of container -->
    </div>
    <!-- end of form -->
    <!-- end of contact -->
    <Toast v-model="toastValue" :error="errorToast"></Toast>
  </section>
</template>
<script>
import DropSelect from "@/components/DropSelect/DropSelect.vue";
import InputFiled from "@/components/InputFiled/InputFiled";
import { sendEmail } from "@/api/question/index.js";
import DefaultParams from "@/utils/defalut_params.js";
import CachSendEmail from "@/utils/cach_send_email.js";
import Toast from "@/components/Toast/Toast";

export default {
  components: {
    DropSelect,
    InputFiled,
    Toast,
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      selectValue: {},
      toastValue: "",
      errorToast: false,
      checked: true,
      dropList: [
        {
          value: "Questions and Comments about the App",
        },
        { value: "How to Manage and Cancel Your Subscription" },
        { value: "How to Request a Refund" },
      ],
    };
  },
  methods: {
    sendMessage() {
      if (
        !this.name ||
        !this.email ||
        !this.message ||
        !this.selectValue ||
        !this.checked ||
        JSON.stringify(this.selectValue) === "{}"
      ) {
        return;
      }
      const canSendEmail = CachSendEmail.sendEmailEnable();
      if (!canSendEmail) {
        // 一个小时内超过两次，禁止发送
        this.errorToast = true;
        this.toastValue = "You operation are too frequent. Take a break!";
        return;
      }
      this.showLoading = true;
      const nativeParams = { ...DefaultParams.getAppInfo() };
      const access_token = nativeParams.access_token;
      delete nativeParams["access_token"];
      const body = {
        ...nativeParams,
        feedback: {
          question_type: this.selectValue.index,
          question: this.selectValue.value,
          name: this.name,
          email: this.email,
          suggestion: this.message,
        },
      };
      sendEmail(body, access_token)
        .then(() => {
          CachSendEmail.storageSendParams();
          this.errorToast = false;
          this.toastValue = "Thanks for submitting.";
          this.clearFrom();
        })
        .catch((err) => {
          console.log("clickSendEmail err", err);
          this.errorToast = true;
          this.toastValue = "Something went wrong.";
        });
    },
    clearFrom() {
      this.name = this.message = this.email = "";
      this.selectValue = {};
    },
  },
};
</script>
