<template>
  <!-- Copyright -->
  <div class="copyright">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <p class="p-small">
            Copyright &copy; {{ yearTime }} Top Stickers Maker Apps
          </p>
        </div>
        <!-- end of col -->
      </div>
      <!-- enf of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of copyright -->
  <!-- end of copyright -->
</template>

<script>
export default {
  data() {
    return {
      yearTime: new Date().getFullYear(),
    };
  },
};
</script>
