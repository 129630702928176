import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import TermsPage from "@/views/TermsPage/TermsPage";
import PrivacyPage from "@/views/PrivacyPage/PrivacyPage";
import ContactUs from "@/views/ContactUs/ContactUs";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/terms",
    name: "terms",
    component: TermsPage,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyPage,
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: ContactUs,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
