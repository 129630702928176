<template>
  <section class="privacy-page">
    <!-- Header -->
    <header id="header" class="ex-header">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>PRIVACY POLICY</h1>
          </div>
          <!-- end of col -->
        </div>
        <!-- end of row -->
      </div>
      <!-- end of container -->
    </header>
    <!-- end of ex-header -->
    <!-- end of header -->
    <Privacy></Privacy>
  </section>
</template>

<script>
import Privacy from "@/components/Privacy/Privacy";
export default {
  components: {
    Privacy,
  },
};
</script>

<style></style>
