<template>
  <div class="form-group input-filed">
    <input
      v-if="inputType !== 'textarea'"
      :type="inputType"
      class="form-control-input"
      :class="activeClass"
      :id="id"
      v-model="inputValue"
      required
      @blur="inputBlur"
    />
    <textarea
      v-else
      class="form-control-textarea"
      :id="id"
      v-model="inputValue"
      cols="30"
      rows="10"
      required
      :class="activeClass"
      @blur="inputBlur"
    ></textarea>
    <label class="label-control" :for="id">{{ label }}*</label>
    <div class="help-block with-errors"></div>
  </div>
</template>

<script>
export default {
  props: {
    inputType: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "text",
    },
    value: {
      default: "",
      type: String,
    },
    id: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      inputValue: "",
      activeClass: "",
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.inputValue = val;
        if (!val) {
          this.activeClass = "";
        }
      },
      immediate: true,
    },
  },
  methods: {
    inputBlur() {
      this.$emit("input", this.inputValue);
      this.activeClass = this.inputValue ? "notEmpty" : "";
    },
  },
};
</script>

<style>
.input-filed input {
  box-shadow: 0 0 0 1000px #413d52 inset;
}
.input-filed input::first-line {
  color: $white;
}
</style>
