<template>
  <section class="terms-page">
    <!-- Header -->
    <header id="header" class="ex-header">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>TERMS OF SERVICE</h1>
          </div>
          <!-- end of col -->
        </div>
        <!-- end of row -->
      </div>
      <!-- end of container -->
    </header>
    <!-- end of ex-header -->
    <!-- end of header -->
    <Terms></Terms>
  </section>
</template>
<script>
import Terms from "@/components/Terms/Terms";
export default {
  components: {
    Terms,
  },
};
</script>
